<template>
  <v-data-table 
    class="simple-table pb-2"
    :headers="headers" 
    :items="items"
    :height="height"
    hide-default-header
    hide-default-footer
    disable-pagination
    fixed-header
    no-data-text="Carregando..."
  >
    <template v-slot:header="{ props: { headers } }">
      <thead>
        <tr>
          <th
            v-for="(header, h) in headers"
            :key="`header-${h}`"
            class="cell header text-body-2 font-weight-bold text--secondary text-truncate"
            :class="[`text-${header.align}`]"
          >
            <span v-if="isTab(header)">
              <a 
                v-for="(title) in header.text"
                :key="`header-title-${title.value}`"
                class="header-tab px-1"
                :class="[ title.id==tab ? 'primary--text' : 'text--disabled' ]"
                @click="selectTab(title.id)"
              >
                {{ title.value }}
              </a>
            </span>
            <span v-else>
              {{ header.text }}
            </span>
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:item="{ item }">
      <tr
        :class="{ 'selectable': selectable }"
        @click="selectItem(item.id)"
      >
        <td 
          v-for="(column, i) in headers"
          :key="'row-'+i"
          :class="[column.type]"
          class="cell text-truncate"
        >
          <span
            :title="item.hasOwnProperty('title') ? item.title : item.value"
          >
            {{ item | format(column) }}
          </span>
        </td>
      </tr>
    </template>
    <template 
      v-slot:no-data
    >
      <div v-if="loading">
        <v-skeleton-loader
          v-for="n in 5"
          :key="'loading-'+n"
          type="text"
          loading
          tile
          :width="random(40,80)+'%'"
          class="my-6"
        />
      </div>
      <div v-else>
        <span class="text-body-2">
          Não há dados para exibir.
        </span>
      </div>
    </template>
  </v-data-table>
</template>

<style>

  .simple-table .header .header-tab:first-child {
    margin-left: -4px;
  }
  .simple-table .header .header-tab:not(:first-child) {
    border-left: 1px solid var(--light-border);
    padding-left: 6px !important;
  }

  .simple-table .selectable {
    cursor: pointer;
  }
  .simple-table .cell {
    max-width: 1px;
  }
  .simple-table .cell.text {
    padding-right: 8px;
  }
  .simple-table .cell.value {
    padding-left: 8px;
  }

  .simple-table .cell:nth-child(n+2) {
    text-align: right;
  }

</style>

<script>

  import { 
    mdiMenuDown,
  } from '@mdi/js';
  import services from '@/services';
  import numeral from 'numeral'


  export default {

    props: {
      data: {
        type: [Array, Object],
        default: () => {
          return null;
        }
      },
      headers: {
        type: Array,
        default: () => {
          return null;
        }
      },
      metric: {
        type: String,
        default: null,
      },
      tab: {
        type: String,
        default: null,
      },
      height: {
        type: [Number, String],
        default: undefined
      },
      maxItems: {
        type: Number,
        default: null
      },
      selectable: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      // loadingAnimation () {
      //   return this.loading;
      // }
    },

    watch: {
      headers: {
        immediate: true,
        deep: true,
        handler (value) {
          // console.log('headers', value);
          _.map(value, (v) => {
            this.input.metric.value = v.selected;
            this.input.metric.items = v.columns;
          });
        }
      },
      data: {
        immediate: true,
        deep: true,
        handler (data) {
          this.refreshItems(data);
        }
      },
      metric: {
        immediate: true,
        handler (value) {
          this.input.metric.value = value;
        }
      },
      tab (tab) {
        this.refreshItems(this.data);
      },
    },

    data: () => ({
      icons: {
        mdiMenuDown
      },
      input: {
        metric: {
          value: null,
          toggle: false,
          items: []
        }
      },
      items: []
    }),

    filters: {
      format (item, column) {
        let formatted = item[column.key];
        const type = column.type;
        const format = _.has(item, 'format') ? item.format : '0,0[.]0[0] a';
        formatted = type=='value' ? numeral(formatted).format(format) : formatted;
        return formatted; 
      }
    },

    methods: {
      ...services,
      
      refreshItems (data) {
        const selected = this.metric;
        const tab = this.tab;
        if (tab!=null) data = data[tab];
        const items = _.reject(selected!=null ? _.map(data, (d) => {
          return {
            title: d.title,
            value: d[selected],
            id: _.has(d, 'id') ? d.id : null
          };
        }) : this.data, d => d==0||d==null);
        this.items = this.maxItems == null ? items : _.slice(items, 0, this.maxItems);
      },

      isTab (header) {
        return typeof header.text == 'object';
      },

      selectTab (tab) {
        this.$emit('tab-change', tab);
      },

      selectItem (item) {
        if (this.selectable) this.$emit('select-item', item);
      },

      selectMetric (metric) {
        this.input.metric.value = metric;
        this.$emit('metric-change', metric);
      }
    }

  }
</script>